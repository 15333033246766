//=> api:Record(交易流水) 
import http from "./http";

/** 流水详情
    @api /user/capital/waterInfo
    @method post
    @params { 
        token,
        jno_acct:"账务系统资金流水唯一标识",
        userType:"个人用户(INNERUSER)" ||  "企业用户(INNERCOMPANY)"  default:个人用户,
    } 
    @data {
        accp_txnno              ACCP系统交易单号
        amt                     出入账金额
        amt_bal                 交易后余额
        date_acct               账务日期
        flag_dc                 账户出入账标识    DEBIT：出账   CREDIT：入账
        jno_acct                资金流水号
        jno_cli                 商户订单号
        memo                    资金流水备注
        oid_acctno              账户号
        oid_partner             商户的唯一编号
        other_acct              对手账号
        other_acct_name         对手户名。
        ret_code                请求结果代码
        ret_msg                 请求结果描述
        txn_seqno               交易流水号
        txn_time                商户系统交易时间
        txn_type                交易类型
    }
*/
export const $apiRecordInfo = (data) => http({ method: 'post', url: '/user/capital/waterInfo', params: data })

/** 流水列表
    @params { token } 
    @data {
        acctbal_list:[
            {
                accp_txnno: "ACCP系统交易单号"  
                amt: "出入账金额"
                amt_bal: "交易后余额"
                date_acct: "账务日期"
                flag_dc: "DEBIT" 出入账标识。 账户出入账标识， DEBIT：出账 CREDIT：入账
                jno_acct: "账务系统资金流水唯一标识"
                jno_cli: "商户订单号"
                memo: "资金流水备注"
                oid_acctno: "账户号"
                txn_time: "商户系统交易时间"
                txn_type: "交易类型"
            }
        ]
        total_num:"总数"
    }
*/
export const $apiRecordList = (data) => http({ method: 'post', url: '/user/capital/water', params: data })
